<template>
  <div>
    <div style="height: 10%" />
    <div class="loginLogo" style="height: 5%; text-align: center">
      {{ title }}회원가입
    </div>

    <div style="text-align: center; height: 70%">
      <termsPage v-if="signType === 0" />

      <signupChoice v-if="signType === 3" />
      <adminInputForm v-else-if="signType === 1" />
      <userInputForm v-else-if="signType === 2" />
    </div>
    <div style="text-align: center; height: 10%">
      <v-btn @click="moveLogin()" text>
        <span style="color: gray"> 계정이 있으신가요? </span>
        <span style="color: #424242"> 로그인으로 돌아가기</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import adminInputForm from "@/components/pages/signup/adminInputForm.vue";
import userInputForm from "@/components/pages/signup/userInputForm.vue";
import signupChoice from "@/components/pages/signup/signupChoice.vue";
import termsPage from "@/components/pages/signup/termsPage.vue";

export default {
  components: {
    adminInputForm,
    userInputForm,
    signupChoice,
    termsPage,
  },
  computed: {
    ...mapGetters({
      signType: "getSignType",
    }),
    title() {
      return this.signType === 0
        ? ""
        : this.signType === 1
        ? "관리자 계정 "
        : "유저 계정 ";
    },
  },
  methods: {
    moveLogin() {
      this.$store.commit("setLoginForm");
      this.$store.commit("setSignType");
    },
  },
};
</script>
