<template>
  <v-app>
    <div class="loginGrid">
      <LeftForm class="informationSector" />
      <LoginForm v-if="this.$store.state.loginForm === 'login'" />
      <SignupForm v-else-if="this.$store.state.loginForm === 'signup'" />
      <PwFindForm v-else />
    </div>
  </v-app>
</template>

<script>
import LoginForm from "../components/pages/login/LoginForm.vue";
import LeftForm from "@/components/pages/login/LeftForm.vue";
import SignupForm from "../components/pages/signup/SignupForm.vue";
import PwFindForm from "@/components/pages/pwFind/PwFindForm.vue";

export default {
  components: {
    LoginForm,
    LeftForm,
    SignupForm,
    PwFindForm,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    // this.onResize();
    // window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    // onResize() {
    //   this.isMobile = window.innerWidth < 600;
    // },
  },
};
</script>

<style lang="scss" src="@/components/pages/common/scss/login.scss"></style>
