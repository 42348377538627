import { mapGetters } from "vuex";
import { validateEmail, validatePassword } from "@/utils/validation";
import { idCheck, companyCheck } from "@/api/auth";

export default {
  data() {
    return {
      businessNumStatus: "", // 사업자 번호 중복 확인
      cerTifiedNum: "", // 이메일로 전달 될 랜덤 숫자값
      erTifiedTime: "", // 발송된 메일 입력 잔여 시간 밀리세컨드 에서 분/초로 변환한 값
      intervalTime: 0, // 랜덤 숫자 시간 설정
      mailCode: "", //입력받은 인증코드
      mailCodeHint: "", //메일 인증 코드 hint text
      isMail: false, // 메일 인증 완료 여부 확인

      company: "",
      uuid: "",
      password: "",
      checkPw: "", //패스워드 확인

      name: "",
      business_num: "",

      company_hint: "회사 명을 입력하세요.",
      business_num_hint: "사업자 번호를 입력하세요",
      uuid_hint: "사용자 아이디를 입력하세요. (이메일 형식)",
      // password_hint: "사용자 비밀번호를 입력하세요.",
      name_hint: "사용자 이름을 입력하세요.",

      isPw: false,
      isPwCheck: false,
    };
  },
  computed: {
    ...mapGetters({
      signType: "getSignType",
    }),
    isValid() {
      return !(
        this.businessNumStatus === "overlab" &&
        validateEmail(this.uuid) &&
        this.password &&
        !validatePassword(this.password) &&
        this.checkPw === this.password &&
        this.name &&
        this.isMail
      );
    },
    companyHint() {
      return this.company ? "" : this.company_hint;
    },
    /**
     * 회사 사업자 번호 활성화 여부
     * 10 글자 이하라면 true
     * 한글이 섞여 있다면 true
     */
    businessBtnCheck() {
      let isBtn = false;
      if (this.business_num.length < 10) isBtn = true;
      else isBtn = isNaN(+this.business_num);
      return isBtn;
    },

    /**
     * 사업자 번호 입력에 따른 출력 txt
     * 1. 입력 x : 사업자 번호를 입력해주세요.
     * 2. 입력 값에 문자가 있을경우: 사업자 번호에는 숫자값 만을 입력해주세요
     * 3. 입력 값이 12미만일경우: 전체 사업자 번호를 입력해주세요
     * 4. 검색 하지 않았을경우ㅣ 사업자 번호를 검색해주세요
     * 5. 검색결과 있을경우
     *  - 일반 유저: 존재하는 사업자 번호입니다.
     *  - 관리자 유저: 이미 등록된 사업자 번호입니다.
     */
    businessNumHint() {
      let text = "";
      if (!this.business_num) text = "사업자 번호를 입력해주세요";
      else if (this.business_num && isNaN(+this.business_num))
        text = "사업자 번호에는 숫자값만 입력해주세요";
      else if (this.business_num && this.business_num.length < 10)
        text = "사업자 번호 전체 (10자)를 입력해 주세요";
      else if (this.business_num.length === 10 && !this.businessNumStatus)
        text = "사업자 번호를 검색하여 중복 확인 해주세요";
      else if (this.businessNumStatus === "overlab")
        text = "등록되어 있는 사업자 번호입니다.";
      else if (this.businessNumStatus === "new")
        text = "등록되지 않은 사업자 번호입니다. 다시 확인해 주십시오.";

      return text;
    },

    certifiedBtn() {
      return !validateEmail(this.uuid) || this.isMail;
    },
    passwordHint() {
      let hint = "";
      if (!this.password) hint = "사용자 비밀번호를 입력하세요.";
      if (this.password && validatePassword(this.password))
        hint = "비밀번호는 8~20자 이상 영문,숫자,특수문자로 입력해주세요";

      return hint;
    },
    isCheckPw() {
      let isCheck = !this.password;
      if (validatePassword(this.password))
        isCheck = validatePassword(this.password);

      return isCheck;
    },
    checkPwHint() {
      let hint = "";

      if (!this.password) hint = "패스워드를 입력해주세요";
      if (this.checkPw && this.password !== this.checkPw)
        hint = "비밀번호가 다릅니다. 확인 부탁드립니다";
      if (this.checkPw && this.password === this.checkPw)
        hint = "비밀번호와 동일합니다.";

      return hint;
    },
    nameHint() {
      return this.name ? "" : this.name_hint;
    },
  },
  methods: {
    // 회사확인
    async companyFind() {
      if (this.business_num.length < 10 && isNaN(+this.business_num)) return;
      try {
        const company = await companyCheck({
          business_num: this.business_num,
        });

        if (company.status !== 200) throw `error : ${company.status}`;

        this.businessNumStatus = company.data.type;
        this.company = company.data.id;
      } catch (e) {
        console.error(e);
      }
    },

    async uuidOverlapCheck() {
      let isOverlap = false;

      try {
        const result = await idCheck(this.uuid);

        if (result.status !== 200) throw `error: ${result.status}`;

        isOverlap = result.data;
      } catch (e) {
        console.log(e);
      }

      return isOverlap;
    },

    // 회원가입
    async signup() {
      if (this.isValid) {
        alert("로그인 정보를 입력하세요.");
        return;
      }

      await this.$store.dispatch("SIGNUP", {
        uuid: this.uuid,
        password: this.password,
        company: this.company,
        name: this.name,
        grade: 0,
      });
    },

    // 임의 의 숫자값을 전달 받은 메일로 전달 하는 함수
    async mailCertified() {
      this.intervalTime = "";
      this.mailCodeHint = "";
      this.mailCode = "";
      this.intervalTime = "";
      this.isMail = false;

      if (await this.uuidOverlapCheck()) {
        this.cerTifiedNum = Math.round(Math.random() * 1000000);
        this.intervalTime = 2 * 60 * 1000;

        this.$store.dispatch("EMAIL_SEND", {
          reciver: this.uuid, //이메일 주소
          title: "wondanMania 회원가입 이메일 인증 코드", // 이메일 제목
          content: `* 본 메일은 발신 전용으로 수신이 불가합니다.<p/>회원가입 인증 번호 : ${this.cerTifiedNum}`, // 내용
        });

        this.setErTifiedTime();
        alert("해당 메일로 인증번호를 전송하였습니다. 확인해주세요");
      } else {
        this.uuid = "";
        this.uuid_hint = "중복된 이메일입니다.";
      }
    },

    // 보안 코드 남은 시간 계산 하는 함수
    setErTifiedTime() {
      const interval = setInterval(() => {
        if (this.intervalTime) {
          this.intervalTime = this.intervalTime - 1000;
          this.erTifiedTimeCaclu(this.intervalTime);
        } else {
          this.cerTifiedNum = "";
          this.erTifiedTime = "";
          clearInterval(interval);
        }
      }, 1000);
      if (this.erTifiedTime) clearInterval(interval);
    },

    // 전달 받은 밀리세컨트 를 분/초 로 변환 하는 기능
    erTifiedTimeCaclu(time) {
      const min = String(Math.floor((time / (1000 * 60)) % 60)).padStart(
        2,
        "0",
      );
      const sec = String(Math.floor((time / 1000) % 60)).padStart(2, "0");
      this.erTifiedTime = `${min}분${sec}초`;
    },

    /**
     * 입력 받은 값과 메인 인증 코드가 같은 값인지 체크 하는 함수
     * 1. 같을 경우 잔여 시간 삭제, 보안 코드 삭제
     * 2. 다를 경우 입력 받은 값을 삭제 하고 보안 코드가 다르다는 안내 출력
     */
    inputCode() {
      if (+this.mailCode === this.cerTifiedNum) {
        this.intervalTime = "";
        this.mailCodeHint = "메일 인증 완료";
        this.isMail = true;
      } else {
        this.mailCode = "";
        this.mailCodeHint = "메일 인증 코드가 맞지않습니다";
      }
    },
  },
};
